import {observer} from 'mobx-react-lite';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {CustomModalProps} from 'src/components/Modal/modal.model';
import {useStore} from 'src/contexts/store.context';

export const DeleteUserAccountModal = observer(({open, onClose}: CustomModalProps) => {
	const {translate} = useTranslate();
	const history = useHistory();

	const {
		auth: {deleteUser},
	} = useStore();

	const deleteItem = () => {
		deleteUser()
			.then(() => {
				history.push('/');
			})
			.catch((error: any) => console.error(error));
	};

	return (
		<Modal open={open} onClose={onClose}>
			<div className="delete-account">
				<h1 className="delete-account__title txt-center txt-h1 txt-secondary">
					{translate('DeleteUserAccountModal.text')}
				</h1>
				<div className="delete-account__action">
					<button
						className="delete-account__button btn__secondary--inverse"
						onClick={() => onClose()}>
						{translate('App.no')}
					</button>
					<button
						className="delete-account__button btn__secondary bg-primary"
						onClick={() => deleteItem()}>
						{translate('App.yes')}
					</button>
				</div>
			</div>
		</Modal>
	);
});
