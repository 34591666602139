import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';

import {Basket} from '../Basket/Basket';

export const CheckoutPageRecap = observer(() => {
	const {translate} = useTranslate();

	return (
		<div className="checkout-page__basket">
			<Basket isCheckoutPage={true} />
			<p className="reacp-text txt-grey-10 txt-xs">{translate('Basket.recap_text')}</p>
		</div>
	);
});
