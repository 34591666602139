import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import { useLocation } from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';

interface BasketHeaderProps {
	isCheckoutPage?: boolean;
}
export const BasketHeader = observer((props: BasketHeaderProps) => {
  const {isCheckoutPage} = props;
	const {translate} = useTranslate();
	const location = useLocation();
	const headerTitle = useMemo(() => {
		return location.pathname.includes('checkout') ? 'CheckoutPageRecap.title' : 'Basket.order_summary';
	}, [location]);
	const {
		basket: {productCount},
  } = useStore();
  
  console.log('isCheckoutPage', isCheckoutPage);

	return (
		<div className="basket-header">
			<h2
				className={`basket-header__title ${
					isCheckoutPage ? 'txt-primary-light' : 'txt-primary-dark'
				} txt-m txt-uppercase special-font ${
					productCount && 'notification notification--left txt-m'
				}`}>
				{translate(headerTitle)}
			</h2>
		</div>
	);
});
