import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';

export const Footer = observer(() => {
	const {translate} = useTranslate();
	const location = useLocation();
	const restaurantsPage = location.pathname.includes('restaurants') && !location.pathname.includes('authenticate');
	const productPage = location.pathname.includes('product');
	const fitMain = useRef('special-footer');

	const {
		restaurant: {currentRestaurant, restaurants},
	} = useStore();

	const addPadding = useCallback(
		() => currentRestaurant && restaurantsPage && restaurants && restaurants.length > 0,
		[restaurants, currentRestaurant, restaurantsPage],
	);

	useEffect(() => {
		if (addPadding() || productPage) {
			const bodyClasses = document.body.classList;
			!bodyClasses.contains(fitMain.current) && document.body.classList.add(fitMain.current);
		} else {
			document.body.classList.remove(fitMain.current);
		}
  }, [addPadding, productPage]);

	return (
		<footer
			className={`footer${addPadding() ? ' footer--tablet-padding' : ''}${
				productPage ? ' footer--padding' : ''
			}`}>
			<div className="footer__container theme-container">
				<div className="footer-block__left">
					<a
						href={process.env.REACT_APP_CONTACT_PAGE_URL}
						target="_blank"
						rel="noopener noreferrer"
						className="footer__button margin-right">
						{translate(`Footer.contact`)}
					</a>
					<a
						href={process.env.REACT_APP_TERM_OF_USE_PAGE}
						target="_blank"
						rel="noopener noreferrer"
						className="footer__button">
						{translate(`Footer.legalsInformations`)}
					</a>
				</div>
				<div className="footer-block__center">
					<p>
						{process.env.REACT_APP_FOOTER_TEXT}
            {translate(`Footer.copyright_link`)}
						<a className="footer__button" target="blank" href="https://acrelec.com/">
              ACRELEC
						</a>
					</p>
				</div>
				<div className="footer-block__right">
					<div
						className="footer__button round round--up"
						onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}></div>
				</div>
			</div>
		</footer>
	);
});
