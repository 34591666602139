import {observer} from 'mobx-react-lite';
import React from 'react';

import {PaymentCheckbox} from 'src/components/Form/PaymentCheckbox';
import { useTranslate } from 'src/components/Languages/translate.hook';

interface DataProtectionCheckboxProps {
	protectionPolicy: boolean;
	setProtectionPolicy: (boolean: boolean) => void;
	offersMessage: boolean;
	setOffersMessage: (boolean: boolean) => void;
}

export const DataProtectionCheckbox = observer((props: DataProtectionCheckboxProps) => {
  const {protectionPolicy, setProtectionPolicy, offersMessage, setOffersMessage} = props;
	const {translate} = useTranslate();

	return (
		<div className="cards-container">
			<PaymentCheckbox
				defaultChecked={protectionPolicy}
				label={
					translate(`CheckoutPage.protection_policy_message_1`) +
					process.env.REACT_APP_POLICY_BRAND_NAME +
					translate(`CheckoutPage.protection_policy_message_2`)
				}
				className={`payment-checkbox ${protectionPolicy && 'checked'}`}
				name={'protection_policy_message_1'}
				isRequired={true}
				onChange={() => {
					setProtectionPolicy(!protectionPolicy);
				}}
			/>
			<PaymentCheckbox
				defaultChecked={offersMessage}
				label={translate(`CheckoutPage.offers_message`) + process.env.REACT_APP_POLICY_BRAND_NAME}
				className={`payment-checkbox ${offersMessage && 'checked'}`}
				name={'offers_message'}
				onChange={() => {
					setOffersMessage(!offersMessage);
				}}
			/>
		</div>
	);
});
