import {BasketProductModelType} from '@acrelec-cloud/apico-cdk';
import {observer} from 'mobx-react-lite';
import React, {useState, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {usePopover} from 'src/components/Popover/popover.hook';
import {useStore} from 'src/contexts/store.context';
import {useMoney} from 'src/hooks/money.hook';
import {useOpeningHours} from 'src/hooks/opening-hours.hook';

interface BasketProps {
	isCheckoutPage?: boolean;
}

export const BasketFooter = observer((props: BasketProps) => {
  const {isCheckoutPage} = props;
	const {translate} = useTranslate();
	const history = useHistory();
	const location = useLocation();
	const {closePopover} = usePopover();
	const [disabled, setDisabled] = useState<boolean>(true);
  const { isOpen } = useOpeningHours();
  
	const {
		basket: {discountProducts, isBasketEmpty, basketTotalWithAllDiscounts, basketTotal},
		restaurant: {currentRestaurant, orderingDisabled},
	} = useStore();
	const [formatMoney] = useMoney();
	const onCheckout = () => {
		history.push('/checkout');
		closePopover();
	};

	useEffect(() => {
		const restaurantIsOpen = currentRestaurant && isOpen(currentRestaurant);

		if (!restaurantIsOpen) {
			setDisabled(true);
		} else {
			setDisabled(isBasketEmpty || orderingDisabled);
		}
	}, [currentRestaurant, isBasketEmpty, orderingDisabled, isOpen]);

	useEffect(() => {
		if (orderingDisabled) {
			setDisabled(true);
		}
	}, [setDisabled, orderingDisabled]);

	return (
		<div className="basket-footer">
			<div className="basket-footer__section">
				{discountProducts && discountProducts.length > 0 && (
					<>
						<p
							className={`${
								isCheckoutPage ? 'txt-primary-light' : 'txt-primary-dark'
							} txt-right basket-footer__section--text`}>
							<span className="txt-bold txt-s">{translate('Basket.total')}:</span>
							<span className="basket-footer__section--total txt-s">
								{formatMoney(basketTotal)}
							</span>
						</p>
						{discountProducts.map((product: BasketProductModelType) => (
							<p key={product.id} className="txt-grey-30 txt-bold txt-s txt-right">
								{translate('Basket.discount_message')} {product.description}
							</p>
						))}
					</>
				)}
				<p
					className={`${
						isCheckoutPage ? 'txt-primary-light' : 'txt-primary-dark'
					} txt-right basket-footer__section--text`}>
					<span className="txt-bold txt-l">{translate('Basket.total_discounted')}:</span>
					<span className="basket-footer__section--total txt-l">
						{formatMoney(basketTotalWithAllDiscounts)}
					</span>
				</p>
			</div>
			{!location.pathname.includes('checkout') && (
				<div className="basket-footer__section">
					<button
						className="basket-footer__button btn__primary"
						disabled={disabled}
						onClick={onCheckout}>
						{translate('Basket.checkout')}
					</button>
				</div>
			)}
		</div>
	);
});
