export const en = {
  App: {
    all: "All",
    calories: "CAL",
    add_cart_btn: "Add to Cart",
    edit_cart_btn: "Update my cart",
    unavailable: "Unavailable",
    messages: {
      qty_max: "You have reached the MAX quantity.",
      qty_max_parent: "You have chosen the MAX options.",
      qty_min: "You have reached the MIN quantity.",
    },
    no: "No",
    yes: "Yes",
    day: {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
    },
  },
  Basket: {
    checkout: "Checkout",
    basket_empty: "Your basket is empty",
    discount_message: "Price applying the discount: ",
    order_summary: "Order summary",
    quantity: "Quantity",
    recap_text:
      "* The detailed receipt will be delivered with your order at the restaurant.",
    show_details: "Show details",
    customize: "Customize",
    subtotal: "Total Exc. VAT",
    taxes: "Taxes",
    total: "Total price before discount",
    total_discounted: "Total price",
    // total: "Total Inc. VAT",
  },
  BasketUpdatedModal: {
    message:
      "Some products of your basket are no longer available, or the price changed and have been removed:",
    button: "Close",
  },
  CancelOrderModalComponent: {
    success_text: "Your order has been canceled!",
    fail_text: "Your order has not been canceled.",
  },
  ConfirmSentModal: {
    success: {
      title: "Your message has been sent !",
      text_problem:
        "Thank you very much, the restaurant will reach out to you soon",
      text: "Thank you very much and see you soon",
    },
    error: {
      title: "Oops, something wrong happened !",
      text: "Please try again later",
    },
    button: "Close",
  },
  CheckinOrderModal: {
    text: "Are you sure you want to send your order now ?",
  },
  CancelOrderPage: {
    title: "Order Cancelation",
    text: "Please check the order's details before proceeding with the cancelation. This action is irreversible.",
    button: {
      cancel: "Confirm Order Cancelation",
      newOrder: "New Order",
    },
    error: "Sorry, you can't cancel this order anymore.",
    done: "This order has been canceled.",
  },
  ModifyOrderPickupPage: {
    title: "Modify pickup time",
    error: "Sorry, you can't modify this order anymore.",
    button: {
      newOrder: "New Order",
    },
    already_injected:
      "You can no longer modify your order because it has already been sent.",
  },
  CheckoutPage: {
    discount: {
      title: "ADD A DISCOUNT",
      button: "Add",
      error: "Invalid discount",
      label: "Discount number",
    },
    email: {
      label: "Email",
      placeholder: "jane.doe@example.com",
    },
    firstname: {
      label: "First Name",
      placeholder: "Jane",
    },
    lastname: {
      label: "Last Name",
      placeholder: "Doe",
    },
    phone_number: {
      label: "Phone number",
      placeholder: "06XXXXXXXX",
    },
    pay: "Pay",
    proceed_pay: "Proceed with payment",
    payment_method_title: {
      user: "PROCEED WITH PAYMENT",
      guest: "FILL YOUR INFORMATION",
    },
    phone: "Phone",
    title: "Checkout page",
    error: {
      name: "Your name should only be composed of letters",
      phone: "Your phone number should only be composed of numbers, + or ( )",
    },
    legal_message: {
      guest: 'By clicking "Proceed with payment", you agree to our',
      user: 'By clicking "Pay", you agree to our',
    },
    offers_message: "I agree to receive offers and news by email from ",
    protection_policy_message_1:
      "I have read and understood the ",
    protection_policy_message_2:
      " personal data protection policy",
    terms_of_use: "terms of use",
    restaurant_closed: "Sorry, the restaurant is now closed.",
  },
  CheckoutPageRecap: {
    title: "Order recap",
  },
  ConfirmButton: {
    btn_next: "Go to order",
  },
  ContactPage: {
    contact_us: "Contact us ",
    link_text: "here",
    text: "You have a question, a suggestion, an issue ?",
    title: "Contact",
  },
  CurrentOrderPage: {
    back: "back to products page",
    no_orders: "No pending orders",
    error: {
      INJECTION_ERROR:
        "An error occurred, your order has not been sent. Please contact us or go to the counter.",
      PAYMENT_ERROR:
        "A problem occurred during the payment, please go to the counter with your order number.",
      CANCELLED:
        "Your order has been canceled. You will not be charged for the amount of the order.",
      CONFLICTED:
        "Unfortunately, one or more products are no longer available. Your order has been canceled, you will not be charged for the amount of the order. Please place another order.",
      unknown_error:
        "An error occurred. Please contact us or go to the counter.",
    },
    order_restaurant_info: {
      will_be_send: "Your order will be sent at:",
      has_been_sent: "Your order has been sent at:",
      in_progress: "Sending your order... ",
    },
    title: "Current orders",
    conflict: {
      confirm: "Confirm Order",
    },
    order_again: "Order again",
    unavailable: "Unavailable",
  },
  CurrentRestaurant: {
    btn_change: "Change",
    btn_choose: "Choose a Store",
    btn_details: "Hours & info",
    no_restaurant: "You didn't select a store yet.",
  },
  DeleteItemModalComponent: {
    text: "Are you sure you want to delete this product?",
  },
  DeleteOrderModal: {
    text: "Are you sure you want to cancel your order?",
    fail_text: "Sorry, you are not allowed to cancel this order anymore.",
    fail_title: "Cancel order failed!",
    success_text: "Your order was successfully canceled.",
    success_title: "Order successfully canceled!",
    button: "Back to current orders",
  },
  DeletePaymentMethodModal: {
    text: "Are you sure you want to delete this payment method ?",
  },
  DeleteUserAccountModal: {
    text: "Are you sure you want to delete your account? This action is irreversible.",
  },
  DetailedFeedbackPage: {
    title: "Detailed feedback",
    email: "Email",
    message: "Message",
  },
  DiscountTitle: "Discount code",
  DifferentPriceModal: {
    title: "The price of the order has changed",
    text: "Do you still want to add it to the basket ?",
    previous_price: "Previous price",
    new_price: "New price",
    validate: "Add my order",
    cancel: "No thanks",
  },
  DifferentRestaurantModal: {
    title: "Your order is unavailable in this restaurant",
    text: "Do you want to change the restaurant and reset your basket?",
  },
  Footer: {
    contact: "Contact",
    legalsInformations: "Terms of use",
    copyright_link: "Copyright Brand Burger 2022 powered by ",
    copyright_text: "ACRELEC",
  },
  ForgotPassword: {
    title: {
      require: "Reset password",
      sent: "If you have an existing account you will receive a password recovery link in your inbox.",
      success: "PASSWORD CHANGED SUCCESSFULLY",
      fail: "OOPS! SOMETHING WENT WRONG",
    },
    link: {
      sent: "I didn't receive an email",
      success: "Go to Login",
      fail: "Try again",
    },
    reset: {
      title: "Reset Password",
      description: "Fill the form to create a new password for your account.",
      button: "Submit",
    },
  },
  Form: {
    radio_button: "Pay with this card ",
    editProfileForm: {
      phone_number: "Phone number",
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      password: "Password",
      confirm_password: "Confirm password",
      placeholder: {
        first_name: "Clark",
        last_name: "Kent",
        email: "Email",
        password: "Password",
        confirm_password: "Confirm password",
      },
    },
    errors: {
      required: "Required",
      required_login: {
        email: "Email required",
        password: "Password required",
      },
      no_message: "",
      pattern: {
        password:
          "Can contain one uppercase, one lowercase, one number and at least 8 characters",
        email: "Email not valid",
        firstName: "The first name can contain only letters, spaces and dashes",
        lastName: "The last name can contain only letters, spaces and dashes",
        number: "Can contain only numbers",
        phone: "Invalid phone number",
      },
      minLength: {
        password:
          "Can contain one uppercase, one lowercase, one number and at least 8 characters",
      },
      maxLength: {
        password: "Can contain up to 30 characters",
      },
      validate: {
        confirm_password: "Passwords must be the same",
      },
    },
    loginForm: {
      old_password: "Old password",
      new_password: "New password",
      new_password_confirm: "Confirm new password",
      email: "Mail",
      placeholder: {
        email: "Email",
        password: "Password",
      },
      error: "Incorrect email or password",
    },
    payment_method: {
      checkbox: "Would you like to save your payment method for later use ?",
    },
    prefix: {
      new: "New",
    },
    reset: "Reset",
    resetPasswordForm: {
      reset: "Reset Password",
      reset_email: "An email will be sent to reset your password",
    },
    submit: "Submit",
  },
  HomeMenuPage: {
    title: "Home Page",
    button: "Start order",
    welcome: "Welcome !",
  },
  IncompatiblePriceModal: {
    title: "Payment failed!",
    content:
      "There was a change on the total price. Please contact the support.",
    button: {
      continue: "Continue With New Price",
      cancel: "Cancel Order",
    },
    error:
      "INCOMPATIBLE PRICE: There was a change on the total price. Please, contact our support.",
  },
  LegalsPage: {
    title: "Terms of use",
  },
  LoginPage: {
    title: "Login",
    or: "or",
  },
  MenuComponent: {
    allergens: "Allergens",
    history: "Order history",
    home: "Home",
    restaurant: "Restaurant",
    restaurants: "Restaurants",
    theme_styles: "Theme Styles",
    order: "Order",
    language: "Language",
    profile: "Profile",
  },
  Navbar: {
    basket: "Basket",
    menu: "Menu",
    payment: "Payment",
    store: "Current restaurant",
    timer: "Order in basket",
  },
  NoAvailableProductsModal: {
    text: "Unfortunately, all the products of this order are unavailable.",
    cancel: "close",
    confirm: "go to order",
  },
  OrderContainer: {
    number: "Order number : ",
    time_estimated: "Estimated time of pickup:",
    cancel_order: "Cancel order",
    confirm: "Confirm",
    change_pickup: "Change pickup time",
    update_order_first: "I'm already in the restaurant, ",
    update_order_second: " now",
    update_order_button: "send my order",
    feedback_sent: "Feedback sent!",
  },
  OrderDisabledModal: {
    basket_not_empty:
      "Do you want to delete your basket and change the restaurant?",
    message: "Online Ordering has been disabled by the restaurant temporarily",
    show_menu: "you can still have a look to the menu but can not order.",
    change: "Select another store",
    continue: "Continue",
    cancel: "Cancel",
  },
  ProductUnavailable: {
    title: "Unavailable product(s)",
    buttons: {
      replace: "Replace the missing product(s)",
      delete: "Delete my order",
      continue: "Continue without the missing product(s)",
    },
  },
  OrderEnable: {
    title: "The orders are enabled",
    content: "Now, you can add products to the cart and place orders.",
    button: "Continue",
  },
  OrderHistoryPage: {
    no_order: "Your order history is empty.",
    title: "Order history",
  },
  OrderPage: {
    error: "Error",
    title: "Order Page",
    not_found_message: "Ops! Page not found",
    not_found_first_message: "This page doesn't exist anymore.",
    not_found_second_message: "We apologize for the inconvenience caused.",
    redirect_message: "Please select ",
    not_found_button: {
      order: "other products",
      store: "another restaurant",
    },
    see_details: "See details of this order",
    hide_details: "Hide details of this order",
    reorder: "Reorder",
  },
  OrderRating: {
    message: "Tell us how we did:",
    success: "Thank you for your feedback!",
    signal: "Report a problem",
    report_sent: "Report sent!",
  },
  OrderRatingModal: {
    success: {
      title: "Thank you for rating your experience !",
      text: "Would you tell us more by sending detailed feedback?",
    },
    error: {
      title: "Oops, something wrong happened !",
      text: "Please try again later",
    },
    no: "No thanks",
    yes: "Yes !",
  },
  ProductCustomizationModal: {
    title: "Customize your ingredients",
    confirm: "Confirm",
  },
  Pagination: {
    textOne: "Orders: ",
    textTwo: " of ",
    button: "Show More",
  },
  Parts: {
    parts: "Customization",
    reset: "Reset",
    part_title: {
      option: {
        show: "Choose an Option",
        hide: "Choose an Option",
      },
      regular: {
        show: "Customize",
        hide: "Customize",
      },
    },
    group_title: {
      included: "Included Items",
      extra: "Boost Your Meal",
      required: "Required Options",
    },
  },
  PaymentCancel: {
    title: "Payment cancel",
    message: "The transaction was canceled by the customer.",
    button: "Go back to the checkout page",
  },
  PaymentFailedModal: {
    title: "Payment failed!",
    content: "Something went wrong, try again.",
  },
  PaymentMethod: {
    validity: "Expiration date: ",
    changeCheckbox: "Use a different card",
  },
  PaymentRefused: {
    title: "Payment refused",
    message: "Please retry payment",
    button: "Go back to the checkout page",
  },
  PaymentSuccessModal: {
    title: "Payment success!",
    content: "Your order number is",
    button: "Track Your Order",
  },
  PickRestaurantButton: {
    btn_choose: "Choose",
    btn_active: "Active",
  },
  ProductChoice: {
    item: "Get item",
    meal: "Get meal",
  },
  ProductPage: {
    title: "Product Page",
    search: "Search for a product",
    addToCartButton: {
      RESTAURANT_CLOSED: "Sorry, restaurant is closed.",
      ORDERS_UNAVAILABLE: "Sorry, orders are temporarily unavailable.",
      REQUIRED_PARTS: "Sorry, some options are required.",
    },
  },
  Profile: {
    account: "Already have an account?",
    confirm: "confirm",
    error: "Error",
    first_name: "First Name",
    form: {
      login: "Login",
    },
    input: {
      password: "Password",
      password_confirm: "Confirm Password",
    },
    last_name: "Last Name",
    link: {
      guest: "Continue as a guest",
      email: "I didn't receive an email",
    },
    login: "Log in",
    logout: "Log out",
    no_account: "Don't have an account?",
    sso_signup: {
      google: "Sign in with Google",
      facebook: "Sign in with Facebook",
    },
    guest: "Don't want to create an account?",
    paragraph: {
      email:
        "A confirmation email will be sent to complete your account creation.",
      email_box: "Please check your email inbox",
    },
    reset_password: "Reset password",
    signup: "Sign up",
    continue_order_guest: "Continue as a guest",
    modal: {
      success: {
        title: "Account created",
        content: "Choose a restaurant and start to order!",
        button: "Choose a Restaurant",
      },
    },
    title: {
      account: "Account confirmation",
      account_update: "Update your account",
      email: "Please enter your email",
      email_sent: "An email has been successfully sent.",
      login: "Login to your account",
      problem: "A problem occurred",
      profile_page: "MANAGE YOUR PROFILE",
      update: "Update profile",
    },
    try_again: "Please try again",
    already_exist: "An account with this email address already exists",
  },
  ProfileConfirmationPage: {
    title: "Création du profile",
  },
  ProfilePage: {
    btn_confirm: "Confirm",
    check_password: "Confirmation of the new password:",
    delete_account: "Delete my account",
    edit_profile: "Edit",
    email: "Email",
    first_name: "First name",
    last_name: "Last name",
    new_password: "New password:",
    old_password: "Old password:",
    payment_method_title: "MANAGE YOUR PAYMENT METHODS",
    reset_password: "Reset password",
    title: "Profile",
    phone_number: "Phone number",
  },
  ProfileCreationPage: {
    title: "Create account",
    legal_message: "By clicking “Confirm”, you agree to our",
    terms_of_use: "terms of use",
  },
  ReorderSuccessModal: {
    title: "The order has been added to the basket",
    text: "Thank you, see you soon!",
    open_basket: "Open basket",
    continue: "Continue",
  },
  ResetBasketModalComponent: {
    text: "The products and sales could be different in each restaurant. Do you want to delete your basket and change the restaurant?",
  },
  RestaurantItem: {
    address: "Address",
    closed: "Closed",
    hours: "Hours",
    infos: "Info",
    open: "Open",
    ordering_disabled: "⚠ Online ordering is temporarily disabled",
  },
  RestaurantsPage: {
    closed: "Closed",
    from: "from",
    title: "Restaurant",
    to: "to",
    map: {
      loading: "Loading Map...",
    },
    search: {
      placeholder: "Search by Zip Code",
    },
    noRestaurant: "Sorry, there is no restaurants at this location.",
  },
  RestaurantAuthenticationPage: {
    authentication_code: "Authentication code",
    button: "Confirm",
    error_message: "Wrong code please try again",
    redirection_message: "Go back to ",
    title: "Please enter the code",
  },
  SelectHourComponent: {
    title: "CHOOSE YOUR PICKUP TIME",
    newTitle: "CHOOSE YOUR NEW PICKUP TIME",
    subTitle: "Available times to pickup today:",
    address: "Restaurant Address: ",
  },
  StripeError: {
    "billing_details[name]": "Invalid name",
    "billing_details[phone]": "Invalid phone",
  },
  StudySurveyFormPage: {
    title: "Report a problem",
    email: "Email",
    phone_number: "Phone number",
    subject: "Subject",
    message: "Message",
    agree: {
      sentence:
        "By submitting this form, I agree that my information will be used exclusively in the context of my request and the ethical and personalized commercial relationship that may result.",
    },
    button: "Send",
    name: "Name",
    recaptcha: {
      policy: "Privacy Policy",
      terms: "Terms of Service",
      sentence: {
        main: "This site is protected by reCAPTCHA and the Google",
        and: "and",
        apply: "apply",
      },
    },
  },
  TechnicalIssuePage: {
    title: "Oops, something went wrong.",
    redirect: "restaurant selection page",
    message_firstPart: "Please go back to the ",
    message_secondPart: " of our website.",
    button: " contact the restaurant",
  },
  UnavailableProductModal: {
    title: "Some products are unavailable",
    text: "Do you want add the other products to the basket?",
    left_button: "no, thanks",
    right_button: "yes",
    missing_products: "Missing product(s): ",
  },
};
