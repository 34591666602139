import { observer } from 'mobx-react-lite';
import React, {useMemo} from 'react';

import { useStore } from 'src/contexts/store.context';

import { BasketEmpty } from './BasketEmpty';
import { BasketFooter } from './BasketFooter';
import {BasketHeader} from './BasketHeader'
import { BasketList } from './BasketList';

interface BasketProps {
  isCheckoutPage?: boolean;
}


export const Basket = observer((props: BasketProps) => {
	const {
		basket: {isBasketEmpty},
  } = useStore();
  
  const { isCheckoutPage } = props;

	const basketContent = useMemo(() => {
		return isBasketEmpty ? <BasketEmpty /> : <BasketList />;
	}, [isBasketEmpty]);

	return (
		<div className="basket">
			<div>
				<BasketHeader isCheckoutPage={isCheckoutPage} />
				{basketContent}
			</div>
			{!isBasketEmpty && (
				<div>
					<BasketFooter isCheckoutPage={isCheckoutPage} />
				</div>
			)}
		</div>
	);
});
